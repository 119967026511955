import React, { lazy, Suspense, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { setHasToken, hydrateApp, loadFxRates } from 'redux/dataStorage/actions';
import { getHasToken } from 'redux/dataStorage/selectors';

import { SessionContext } from 'components/Sessions/SessionContext';
import { featureDecisions } from 'featureDecisions';
import { getLatestFxRatesRequest } from 'utilities/currencyUtilities';
import {
  getHasValidToken,
  getUserDataRequest,
  handleSessionLogout,
  LOGOUT_STATUS_TYPES,
} from 'utilities/sessionUtilities';

import { NotificationsListener } from '../Notifications/listeners/NotificationsListener';

import { AddonPanel } from './Addons/AddonPanel';
import { GlobalModalsContainer } from './GlobalModals/GlobalModals';
import { PageRouter } from './PageRouter';

const MockDataConsole = lazy(() => import('./Addons/MockDataConsole/MockDataConsole'));
const AnalyticsToggler = lazy(() => import('./Addons/AnalyticsToggler/AnalyticsToggler'));

export const App = () => {
  const dispatch = useDispatch();

  const context = useContext(SessionContext);
  const hasToken = useSelector(getHasToken);

  useEffect(() => {
    const init = async () => {
      const deviceHasToken = await getHasValidToken();
      if (deviceHasToken) {
        try {
          const userDataRes = await getUserDataRequest();
          dispatch(hydrateApp(userDataRes.data.data));

          const fxRes = await getLatestFxRatesRequest();
          dispatch(loadFxRates(fxRes.data.data));
          dispatch(setHasToken(true));

          context.resetSessionManager();
          context.handleEvent();
        } catch (e) {
          if (e.response && e.response.status >= 400) {
            handleSessionLogout(LOGOUT_STATUS_TYPES.ACCESS_TOKEN_EXPIRED);
          }
          dispatch(setHasToken(false));
        }
      } else {
        dispatch(setHasToken(false));
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={null}>
      <AddonPanel>
        {!hasToken && featureDecisions.mockServerResponses && <MockDataConsole />}
        {featureDecisions.useAnalyticsToggler && <AnalyticsToggler />}
      </AddonPanel>
      <GlobalModalsContainer />
      <NotificationsListener />
      <PageRouter />
    </Suspense>
  );
};
